import * as React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl';

import Page from '../components/Page'
import IndexLayout from '../layouts'
import styled from '@emotion/styled'
import ContextConsumer from '../components/Context'

const LilaContainer = styled.div`
width:100%;
margin-top:10rem;
text-align-center;
`

const H1 = styled.h1`
text-align:center;
`
const NotFoundPage = ({pathContext: { locale }}) => (

  <ContextConsumer>
    {({ data, set }) => (
  <IndexLayout locale={locale}>
    <Page>
      <LilaContainer>

        <H1><FormattedMessage id={'404_1'}></FormattedMessage></H1>
        <p>
          <FormattedMessage id={'404_2'}></FormattedMessage> <Link to="/"><FormattedMessage id={'404_3'}></FormattedMessage></Link>
        </p>

       </LilaContainer>
    </Page>
  </IndexLayout>
)}
  </ContextConsumer>
      )

export default NotFoundPage
